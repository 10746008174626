<template>
  <BCol lg="4">
    <BTable
      class="border rounded"
      striped
      hover
      small
      responsive
      :items="dataToSelectSeat"
      thead-class="text-dark align-middle text-nowrap"
      :fields="['passenger', 'seat', 'price', 'action']"
      show-empty
      :empty-text="getIsEmptySeatMapData === 'loading' ? $t('reservation.loadingData') : $t('reservation.noSeatMapData')"
    >
      <template
        v-for="(column, indexColumn) in ['passenger', 'seat', 'price', 'action']"
        v-slot:[`head(${column})`]="data"
      >
        <span :key="indexColumn">
          {{ $t(`reservation.${data.column}`) }}
        </span>
      </template>

      <template #cell(passenger)="data">
        <span class="text-truncate font-small-4 text-dark">
          {{ data.item.passengerData.lastName }}
          {{ data.item.passengerData.firstName }}
        </span>
      </template>

      <template #cell(seat)="{item}">
        <div
          class="d-flex-center"
          style="height: 45px; width: 45px;"
        >
          <BButton
            v-if="item.seatSelected"
            variant="success"
            :disabled="true"
            class="p-0 my-50 cursor-not-allowed"
            style="height: 40px; width: 40px;"
          >
            {{ `${item.seatSelected.row}${item.seatSelected.col}` }}
          </BButton>
          <span v-else> - </span>
        </div>
      </template>

      <template #cell(price)="{item}">
        <span
          v-if="item.seatSelected"
          class="text-truncate font-weight-bold"
        >
          {{
            isEmpty(item.seatSelected.fares)
              ? '0'
              : formatCurrency(item.seatSelected.fares[0].total)
          }}
        </span>
        <span v-else> - </span>
      </template>

      <template #cell(action)="{item}">
        <div class="d-flex flex-nowrap justify-content-center">
          <BButton
            v-if="item.seatSelected && !item.alreadySeat"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            :class="item.alreadySeat ? 'cursor-not-allowed' : ''"
            :disabled="item.alreadySeat"
            @click="() => $emit('handleDeleteSeat', item)"
          >
            <feather-icon icon="TrashIcon" />
          </BButton>
          <span v-else> - </span>
        </div>
      </template>
    </BTable>

    <!-- <BRow
      v-if="seatsData && ['1S','1S_CTRL'].includes(bookingSource) && seatsData.seatsMap && ['321', '350', '787'].includes(seatsData.seatsMap.aircraft)"
      no-gutters
      class="font-weight-bolder mr-0 ml-1 border-bottom pb-1 mb-3"
    >
      <div class="mt-3">
        <p
          v-if="['FLEX', 'LITE AND SUPER LITE', 'CLASSIC'].includes(getBookingClassBySegment(segmentData))"
          class="text-warning"
        >
          CHÚ Ý:
        </p>

        <div v-if="['FLEX'].includes(getBookingClassBySegment(segmentData))">
          <p>
            <span class="text-warning">NHÓM ECO 1</span>: PHỔ THÔNG LINH HOẠT
          </p>
          <p>
            - <span class="text-warning">MIỄN PHÍ CHỌN CHỖ</span> ngay khi vừa mua vé trong Khu vực 2,3,4 và luôn được xếp chỗ HÀNG ĐẦU trên máy bay (từ hàng ghế 14 với tàu bay A321 hoặc 16 với tàu bay B787/A350)
          </p>
        </div>

        <div v-if="['CLASSIC'].includes(getBookingClassBySegment(segmentData))">
          <div>
            <span class="text-warning">NHÓM ECO 2</span>: PHỔ THÔNG TIÊU CHUẨN
          </div>
          <div>
            - <span class="text-warning">MIỄN PHÍ CHỌN CHỖ</span> ngay khi vừa mua vé trong Khu vực 3,4 (từ hàng ghế số 25 với tàu bay A321 hoặc 26 với tàu bay B787/A350)
          </div>
        </div>

        <div v-if="['LITE AND SUPER LITE'].includes(getBookingClassBySegment(segmentData))">
          <p>
            <span class="text-warning">NHÓM ECO 3,4</span>: PHỐ THÔNG TIẾT KIỆM và SIÊU TIẾT KIỆM
          </p>
          <p>
            - <span class="text-warning">MIỄN PHÍ CHỌN CHỖ</span> ngay khi vừa mua vé trong Khu vực 4 (từ hàng ghế số 35 với tàu bay A321 hoặc 31 với tàu bay 787/350)
          </p>
        </div>
      </div>
    </BRow> -->

    <b-alert
      v-if="seatsData && ['QH'].includes(bookingSource) && seatsData.seatsMap"
      variant="danger"
      show
      class="px-1 py-50 mt-1 fw-700"
    >
      QH: (Lỗi từ hãng) Khi chọn chỗ ngồi mà giá thể hiện là 0đ, đại lý vui lòng liên hệ booker để kiểm tra lại !
    </b-alert>
  </BCol>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BAlert,
  VBTooltip,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import Ripple from 'vue-ripple-directive'

import { formatCurrency } from '@core/utils/filter'

// FIXME dùng với modify/ancillary-services
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    segmentData: {
      type: Object,
      default: () => {},
    },
    getIsEmptySeatMapData: {
      type: [String, null],
      default: () => null,
    },
    dataToSelectSeat: {
      type: Array,
      default: () => [],
    },
    bookingSource: {
      type: String,
      required: true,
    },
    seatsData: {
      type: [Object, null],
      default: () => null,
    },
  },
  setup() {
    // function getBookingClassBySegment(segmentData) {
    //   let result
    //   if (['Q', 'N', 'R', 'T', 'E', 'P', 'A', 'G'].includes(segmentData.segment.bookingClass)) {
    //     result = 'LITE AND SUPER LITE'
    //   } else if (['S', 'H', 'K', 'L'].includes(segmentData.segment.bookingClass)) {
    //     result = 'CLASSIC'
    //   } else if (['Y', 'B', 'M'].includes(segmentData.segment.bookingClass)) {
    //     result = 'FLEX'
    //   }
    //   return result
    // }
    return {
      isEmpty,
      // getBookingClassBySegment,
      formatCurrency,
    }
  },
}
</script>
